import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"

function Seo({ lang, meta, title, schemaMarkup, canonicalUrl }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            author
            siteUrl
            img
          }
        }
      }
    `
  )

  const intl = useIntl()
  const defaultTitle = site.siteMetadata?.title
  const href = site.siteMetadata.siteUrl
  const image = site.siteMetadata.img
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      link={[
        {
          rel: "canonical",
          href: canonicalUrl ? `${href}/${canonicalUrl}` : href,
        },
      ]}
      meta={[
        {
          name: `description`,
          content: intl.formatMessage({ id: "description" }),
        },
        {
          name: `keywords`,
          content: intl.formatMessage({ id: "keywords" }),
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:url`,
          content: href,
        },
        {
          property: `og:description`,
          content: intl.formatMessage({ id: "description" }),
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: intl.formatMessage({ id: "description" }),
        },
      ].concat(meta)}
    >
      {schemaMarkup && (
        <script type="aplication/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      )}
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
